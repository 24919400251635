:root {
  --main-bg-color: #13161f;
  --secondary-color: #F7931A;
  --font-color: white;
  --cool-gray: #C1C1C1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.a{
  text-decoration: none;
}

.App-body {
  background-color: var(--main-bg-color);
  min-height: 100vh;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--font-color);
  text-align: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.App-link {
  color: #61dafb;
}

.color-nav{
  background-color: var(--secondary-color);
  font-family: 'Titillium Web', sans-serif;
  z-index: 10;
}

.modal{
  color: var(--main-bg-color);
  font-family: 'Titillium Web', sans-serif;
}

.modal-footer {
  padding: 0.5rem !important;
}

.nav-link {
  color: white !important;
}

.card.color-card{
  background-color: var(--cool-gray);
  
  font-family: 'Titillium Web', sans-serif;
  color: #13161f;
}

.white-border{
  border-radius: 20px;
  border: 2px solid;
  border-color: var(--cool-gray);
}

.rhap_container{
  border-radius: 25px;
  border: 2px solid;
  border-color: var(--cool-gray) !important;
}

button:focus {
  box-shadow: none !important;
}

.list-group-custom .list-group-custom-item{
  background-color: var(--main-bg-color);
  color: var(--font-color);
  border-color: var(--font-color);
}

.bottom-element{
  position: fixed;
  bottom: 0;
}

#background-video {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0.02 !important;
}